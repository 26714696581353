import React, { useEffect, useState } from 'react';
import twitterLogo from './assets/twitter-logo.svg';
import './App.css';
import {Wallet} from "./Wallet.js"
import { Contract } from './Contract.js';
import { MintCharacter} from "./Components/SelectCharacter/SelectCharacter.js"
import {Character} from "./Components/Character/Character.js"
import Loading from "./Components/LoadingIndicator/index.js"
// Constants
const TWITTER_HANDLE = '_buildspace';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

const Home = () => {

  //checks for wallet on startup
  const [wallet, setWallet] = useState(null);
  const [contract, setContract] = useState(null)
  const [character, setCharacter] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [boss, setBoss] = useState(null)
  useEffect(function() {
    (async function() {
      if (await Wallet.checkIfWalletIsConnected()) {
        let account = await Wallet.generateWallet();
        if (account) {
          setWallet(account);
        }
        if (window.ethereum) {
          window.ethereum.on("accountsChanged", function(accounts) {
            if (accounts[0]) {
              setWallet(new Wallet(accounts[0]))
            }
          })
        }
      }
      
    })() 
    return () => {
        contract.contract.off("CharacterNFTMinted")
        if (window.ethereum) {
          window.ethereum.off("accountsChanged")
        }
    }
  }, []);
  useEffect(function() {
    (async function() {
      if (wallet) {
        setLoading(true)
        let contract = new Contract()
        let boss = await contract.getBoss()
        setBoss(boss)
        let character = await contract.getCharacterNFT();
        setContract(contract);
        setCharacter(character);
        setLoading(false);
       
      }  
    })()
    
  },[wallet]);
  //mints a character and then updates character list
  async function setCharacters() {
    setError(null)
    async function updateList(sender, tokenId, chracterId) {
      console.log("minted")
      if (sender.toUpperCase() == wallet.account.toUpperCase()) {
        try {
          let txn = await contract.getJson(tokenId);
          let json = JSON.parse(txn);
          let characters = [...character]
          characters.push(json)
          setCharacter(characters)
          setLoading(false)
        } catch(err) {
          console.log(err)
          setError("An Error Occured Maybe Try Giving It More Gas")
          setLoading(false)
        }
      }
      
    }
    contract.contract.on("CharacterNFTMinted", updateList)
    setLoading(true)
    try {
      let txn = await contract.contract.mintCharacterNFT()
      await txn.wait();
    } catch(err) {
      setError("An Error Occured Maybe Try Giving It More Gas")
      setLoading(false)
    }
    
  }
  function renderButton() {
    if (loading) {
      return (
        <div className = "column-flex">
          <Loading />
          <h2 style = {{color:"white"}}>Loading...</h2>
        </div>
      )
    }
    //if their wallet is not connected return a connect Wallet button
    if (!wallet) {
      return (
        <button
              className="cta-button connect-wallet-button"
              onClick={async () => {
                setWallet(await Wallet.connectWalletAction())
              }}
              >
              Connect Wallet To Get Started
          </button>
      )
    } else if(!character) {
      return (
        <MintCharacter setCharacterNFT = {setCharacters} contract = {contract} />
      )
    } else {
      return ( 
        <div className = "column-flex">
          <MintCharacter setCharacterNFT = {setCharacters} contract = {contract} />
          {character.map(x => (
              <Character character={x} key = {x.name} />
          ))}

        </div>
      )
    }
  }
  return (

    <div>
        <div className="connect-wallet-container column-flex">
            {boss && <Character character = {boss} />}
        </div>
        {wallet &&
        <button className="cta-button connect-wallet-button" >
            <a href = "/attack" style =  {{textDecoration:"none", color:"white"}}>
            Fight The Boss
            </a>
        </button> }
        <div>
          {error && 
              <h2 style = {{color:"red"}}>{error}</h2>
          }
        </div>
        <div className="connect-wallet-container">
            
            {renderButton()}
          </div>
        
      </div>
  );
};

export default Home;
