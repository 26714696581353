

export class Wallet {
    //checks to see if user has meta mask installed
    static async checkIfWalletIsConnected() {
        const {ethereum} = window
        if (!ethereum) {
            alert("Install Meta Mask")
            return false
        } else {
            console.log("We all good")
            return await this.checkifWalletIsRinkeby();
        }
    }
    static async checkifWalletIsRinkeby() {
        const {ethereum} = window
        if (!ethereum) {
            alert("Install Meta Mask")
            return false
        }
        try {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x4' }],
            });
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            alert("Please Connect To Rinkeby")
            // handle other "switch" errors
            return false
          }
          return true;
    }
    //generates a wallet with a default account
    static async generateWallet() {
        let account = await this.getDefaultAccount();
        if (account) {
            return new Wallet(account)
        } else {
            return null
        }
    }

    constructor(account) {
        this.account = account
        console.log(account)
    }
    static async connectWalletAction() {
        try {
            const {ethereum} = window 
            if (!ethereum) {
                alert("Get Metamask")
                return;
            }
            if (!await this.checkifWalletIsRinkeby()) {
                alert("Connect To Rinkeby")
                return;
            }
            const accounts = await ethereum.request({method:"eth_requestAccounts"})
            return new Wallet(accounts[0])
        } catch(err) {
            console.log(err)
        }
    }
    static async getDefaultAccount() {
        const {ethereum} = window
        try {
            const accounts = await ethereum.request({method:"eth_accounts"})

            if (accounts.length) {
                return accounts[0]
            } else {
                return false
            }
        } catch(err) {
            console.log(err)
        }
        
    }
}