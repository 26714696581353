import React, {useState, useEffect} from "react"
import { Contract } from "./Contract"
import {Wallet} from "./Wallet"
import {Arena} from "./Components/Arena/Arena"
import { Character } from "./Components/Character/Character"
import {CharacterSelectPage} from "./Components/SelectCharacter/SelectCharacter"
function HomeButton() {
    return (
        <button className="cta-button connect-wallet-button" >
            <a href = "/" style =  {{textDecoration:"none", color:"white"}}>
            Return Home
            </a>
        </button>
    )
}
function Attack() {
    const [character, setCharacter] = useState(null)
    const [nfts, setNfts] = useState([])
    const [contract, setContract] = useState(null)
    const [wallet, setWallet] = useState(null)
    useEffect(function() {
        (async function() {
            if (await Wallet.checkIfWalletIsConnected()) {
                let account = await Wallet.generateWallet();
                if (account) {
                    setWallet(account);
                }
                if (window.ethereum) {
                    window.ethereum.on("accountsChanged", function(accounts) {
                    if (accounts[0]) {
                        setWallet(new Wallet(accounts[0]))
                    }
                    })
                }
            }
        })()

        return () => {
            if (window.ethereum) {
              window.ethereum.off("accountsChanged")
            }
        }
    }, [])
    useEffect(function() {
        (async function() {
            let contract = new Contract()
            let characters = await contract.getCharacterNFT();
            setContract(contract);
            setNfts(characters);
        })()
    }, [wallet])
    function render() {
        if (!wallet || !nfts) {
            return (
              <div />
            )
        } else if (!character) {
            return (<CharacterSelectPage setCharacter = {setCharacter} 
                characters = {nfts} />)
        } else {
            return (
                <Arena contract = {contract} character = {character} />
            )
        }
    }
    return (
        <div>
            <HomeButton />
            <div className="connect-wallet-container">
            {render()}
          </div>
        </div>
    )
}

export default Attack