import { useEffect, useState } from "react"
import { Character } from "../Character/Character"
import LoadingIndicator from "../LoadingIndicator"

export const Arena = (props) => {
    const {contract} = props 
    let char = props.character
    const [boss, setBoss] = useState(false)
    const [loading, setLoading] = useState(false)
    const [character, setCharacter] = useState(char)
    const tokenId = character.name.split("#: ")[1]
    const [error, setError] = useState(null)
    useEffect(function() {
        (async function() {
            let boss = await contract.getBoss()
            console.log(boss)
            setBoss(boss)
        })()
        return () => {
            contract.contract.off("BossAttacked")
        }
    },[])
    async function attack() {
        if (character.attributes[0].value == 0) {
            setError("No Soup For You!!! Your Character Is Dead")
            return
        }
        if (boss.attributes[0].value == 0) {
            setError("Congradulations you graduated!")
            return
        }
        function onAttack(newBossHp, newPlayerHp, tokenId) {
            boss.attributes[0].value = newBossHp 
            if(newBossHp  == 0) {
                contract.contract.off("BossAttacked")
                setError("You have defated Purdue")
            } else {
                setError("Keep Going!")
            }
            if (newPlayerHp == 0) {
                setError("Your Character is Dead 😵")
            }
            character.attributes[0].value = newPlayerHp
            setBoss(boss => ({...boss}))
            setCharacter(character => ({...character}))
            setLoading(false)
        }
        try {
            setLoading(true)
            await contract.attackBoss(tokenId)
            contract.contract.on("BossAttacked", onAttack)
            setError(null)
        } catch(err) {
            console.log(err)
            setLoading(false)
        }
    }
    return (
        <div className="connect-wallet-container column-flex">
            {boss && 
                <Character character = {boss} />
            }
            {error &&
                <h2 style = {{color:"red"}}>{error}</h2>
            }
            {loading ?
                <div className = "column-flex">
                    <LoadingIndicator />
                    <h2 style = {{color:"white"}}>Fighting...</h2>
                </div>
                :
                <button className="cta-button connect-wallet-button" onClick = {attack} >
                    Attack The Boss
                </button>
            }
            
            <Character character = {character} />
        </div>
    )
}