import { ethers } from 'ethers';
import BossABI from "./utils/Boss.json"

const address = "0xB64fB7Ca6a94259C5c5Fc655EfcC0D0df838BBF9"
export class Contract {
    address = address
    constructor() {
        this.provider = new ethers.providers.Web3Provider(window.ethereum)
        this.signer = this.provider.getSigner()
        this.contract = new ethers.Contract(this.address, BossABI.abi, this.signer)
    }
    static getOpenSeaLink(tokenId) {
        return `https://testnets.opensea.io/assets/${address}/${tokenId}`
    }
     async getCharacterNFT() {
        let txn = await this.contract["getNFTs()"]()
        //if user has at least one nft get its data
        if (txn.length > 0) {
            let characters = []
            for(let i = 0; i < txn.length; i++) {
                characters.push(
                    JSON.parse(await this.contract.getJson(txn[i]))
                )
            }
           return characters;
        } else {
            return [];
            console.log("No Nft found")
        }
    }
    //gets the boss
    async getBoss() {
        let boss = await this.contract.getBoss()
        boss = {...boss}
        boss.attributes = []
        boss.attributes.push({max_value:boss.maxHp, value: boss.hp})
        console.log(boss)
        return boss
    }
    async getJson(tokenId) {
        return await this.contract.getJson(tokenId);
    }
    async attackBoss(tokenId) {
        await this.contract["attackBoss(uint256)"](tokenId)
    }
    async mintCharacterNFT() {
        await this.contract.mintCharacterNFT();
        return await this.getCharacterNFT();
    }
}