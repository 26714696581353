import './SelectCharacter.css';
import {Character} from "../Character/Character.js"

/*
 * Don't worry about setCharacterNFT just yet, we will talk about it soon!
 */
export const MintCharacter = ({ setCharacterNFT, contract }) => {
  return (
    <div className="select-character-container">
      <h2>Mint Your Character</h2>
      <button
              className="cta-button connect-wallet-button"
              onClick={async () => {
                setCharacterNFT();
              }}
              >
              Mint Random Challenger
        </button>
    </div>
  );
};

export const CharacterSelectPage = ({setCharacter, characters}) => {
  function onCharacterSelect(character) {
    return function() {
      setCharacter(character)
    }
    
  }
  return (
    <div className = "column-flex" style = {{paddingTop:"2rem"}}>
      <p className="sub-text">Select Your Challenger!</p>
      {characters.map(x => (
        <Character className = {"hover"} key = {x.name} character = {x} 
          onClick = {onCharacterSelect(x)} />
      ))}
    </div>
  )
}


export default MintCharacter;