import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import twitterLogo from './assets/twitter-logo.svg';
import "./App.css";
import Home from "./Home.js"
import Attack from "./Attack.js"
const TWITTER_HANDLE = 'rfheise';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
function App() {
  return (
      <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text">⚔️ Boiler Bytes ⚔️</p>
          <p className="sub-text">Can You Handle The True Power?</p>
        </div>  
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path = "/attack" element={<Attack />} />
        </Routes>
      </BrowserRouter>
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`built by @${TWITTER_HANDLE}`}</a>
        </div>
      </div>
    </div>
    
  );
}

export default App